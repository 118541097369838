import naja from 'naja';
import netteForms from 'nette-forms';

naja.formsHandler.netteForms = netteForms;

// nodes
import 'bootstrap/dist/js/bootstrap.bundle';
import 'jquery-ui-dist/jquery-ui';

// component
import Scroll from './component/Scroll';
import GeneralSwiper from './component/GeneralSwiper';
import SweetAlert from './component/SweetAlert';
import Flashes from './component/Flashes';
import PhotoSwipeGallery from "./component/PhotoSwipe";
import Services from "./component/Services";
import CookieConsent from "./component/CookieConsent";

window.addEventListener('DOMContentLoaded', (event) => {
    naja.initialize();
    Scroll.initialize();
    GeneralSwiper.initialize();
    SweetAlert.init();
    Flashes.init();
    PhotoSwipeGallery.init();
    Services.init();
    CookieConsent.init();
});
