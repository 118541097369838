import $ from 'jquery';

export default class Flashes {
    static init() {
        this.closeFlashes();
    }

    static closeFlashes() {
        let closeButton = $('#jsAlertClose');

        closeButton.on('click', function () {
            $('#snippet--flashes').remove();
        });
    }
}