import 'vanilla-cookieconsent';

export default class CookieConsent {
    static init() {
        this.hookCookies();
    }

    static hookCookies() {
        var cookieconsent = initCookieConsent();

        var logo = '<div class="d-flex align-items-center"><h2 class="me-2 navbar-brand">Studio Luci</h2><img alt="Icon - Lily" width="50" height="51"  src="./static/flowerSmall.png"></div>';

        cookieconsent.run({
            current_lang : 'cs',
            autoclear_cookies : true,                   // default: false
            cookie_expiration : 365,                    // default: 182
            page_scripts: true,                         // default: false

            gui_options: {
                consent_modal: {
                    layout: 'box',                      // box,cloud,bar
                    position: 'bottom right',           // bottom,middle,top + left,right,center
                    transition: 'zoom'                 // zoom,slide
                },
                settings_modal: {
                    layout: 'box',                      // box,bar
                    // position: 'left',                // right,left (available only if bar layout selected)
                    transition: 'zoom'                 // zoom,slide
                }
            },

            onFirstAction: function(){
                //console.log('onFirstAction fired');
            },

            onAccept: function (cookie) {
                //console.log('onAccept fired ...');
            },

            onChange: function (cookie, changed_preferences) {
                //console.log('onChange fired ...');

                // If analytics category is disabled => disable google analytics
                if (!cc.allowedCategory('analytics')) {
                    typeof gtag === 'function' && gtag('consent', 'update', {
                        'analytics_storage': 'denied'
                    });
                }
            },

            languages: {
                'cs': {
                    consent_modal: {
                        title: 'Používáme cookies!',
                        description: 'Používáme cookies, abychom Vám umožnili pohodlné prohlížení webu a díky analýze provozu webu neustále zlepšovali jeho funkce, výkon a použitelnost. K jejich aktivaci potřebujeme Váš souhlas. <button type="button" data-cc="c-settings" class="cc-link">Zobrazit detaily</button>',
                        primary_btn: {
                            text: 'Přijmout vše',
                            role: 'accept_all'              // 'accept_selected' or 'accept_all'
                        },
                        secondary_btn: {
                            text: 'Nastavení',
                            role: 'settings'                // 'settings' or 'accept_necessary'
                        }
                    },
                    settings_modal: {
                        title: logo,
                        save_settings_btn: 'Uložit nastavení',
                        accept_all_btn: 'Přijmout vše',
                        reject_all_btn: 'Zamítnout vše',
                        close_btn_label: 'Zavřít',
                        cookie_table_headers: [
                            {col1: 'Název'},
                            {col2: 'Doména'},
                            {col3: 'Expirace'},
                            {col4: 'Popis'}
                        ],
                        blocks: [
                            {
                                title: 'Použití cookies',
                                description: 'Soubory cookie používáme k zajištění základních funkcí webových stránek a ke zlepšení Vašeho pohodlí při použití našich stránek. U každé kategorie si můžete zvolit, zda se chcete přihlásit nebo odhlásit, kdykoli budete chtít. Další podrobnosti týkající se souborů cookie a dalších citlivých údajů naleznete v úplném znění <a href="/ochrana-osobnich-udaju" class="cc-link">zde</a>.'
                            }, {
                                title: 'Nezbytné cookies',
                                description: 'Tyto soubory cookie jsou nezbytné pro správné fungování webových stránek. Bez těchto souborů cookie by webové stránky nefungovaly správně.',
                                toggle: {
                                    value: 'necessary',
                                    enabled: true,
                                    readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                                }
                            }, {
                                title: 'Analytické cookies',
                                description: 'Tyto soubory cookie nám umožňují zapamatovat si volby, které jste provedli v minulosti.',
                                toggle: {
                                    value: 'analytics',     // there are no default categories => you specify them
                                    enabled: false,
                                    readonly: false
                                },
                                cookie_table: [
                                    {
                                        col1: '^_ga',
                                        col2: 'google.com',
                                        col3: '2 roky',
                                        col4: 'Slouží k rozlišení uživatelů.',
                                        is_regex: true
                                    },
                                    {
                                        col1: '_gid',
                                        col2: 'google.com',
                                        col3: '1 den',
                                        col4: 'Slouží k rozlišení uživatelů.',
                                    }
                                ]
                            },{
                                title: 'Cookies pro reklamu a cílení',
                                description: 'Tyto soubory cookie shromažďují informace o tom, jak webové stránky používáte, které stránky jste navštívili a na které odkazy jste klikli. Všechny údaje jsou anonymizované a nelze je použít k Vaší identifikaci.',
                                toggle: {
                                    value: 'targeting',
                                    enabled: false,
                                    readonly: false
                                }
                            },{
                                title: 'Více informací',
                                description: 'V případě jakýchkoli dotazů týkajících se našich zásad o souborech cookie nás <a class="cc-link" href="/kontakt#contactForm">kontaktujte</a>.',
                            }
                        ]
                    }
                }
            }

        });
    }
}