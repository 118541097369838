import Swal from 'sweetalert2';
import $ from 'jquery';

export default class SweetAlert {
    static init() {
        this.initializeAlert();
    }

    static initializeAlert() {
        let flashes = $('#snippet--flashes');
        let alert = $('#snippet--flashes > div');
        let alertType = alert.data('alert-type');

        if (flashes.children().length > 0) {
            Swal.fire({
                text: alert.data('alert-message'),
                icon: alertType === 'danger' ? 'error' : alertType,
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }
}