import $ from 'jquery';

export default class Services {
    static init() {
        this.hookServices();
    }
    static hookServices() {

        $(".service-btn").click(function() {

            let cardElement = $(this).parent().parent();
            let accordionElement = cardElement.parent();

            if(cardElement.attr('class') === 'card active-service'){
                cardElement.removeClass('active-service').index();
            }
            else{
                accordionElement.children('.card.active-service').removeClass('active-service').index();
                cardElement.addClass('active-service').index();
            }
        });

        if(window.location.pathname === '/sluzby' && window.location.hash !== ''){
            let fragment = decodeURI(window.location.hash).substring(1);
            let card = $("[id='"+ fragment +"']").parent();
            let serviceButton = card.children().children();

            if(card.attr('class') !== 'card') return;

            card.addClass('active-service').index();
            serviceButton.removeClass('collapsed');
            serviceButton.attr('aria-expanded', 'true');
            card.children('div.collapse').addClass('show');
        }
    }
}