import Swiper, {EffectCoverflow, Pagination, Autoplay} from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-coverflow';
import 'swiper/css/bundle';

export default class GeneralSwiper {
    static initialize() {
        this.hookSwiper();
    }

    static hookSwiper() {
        const swiper = new Swiper('.swiper', {
            modules: [EffectCoverflow, Pagination, Autoplay],
            effect: 'coverflow',
            grabCursor: true,
            loop: true,
            centeredSlides: true,
            slidesPerView: "auto",
            speed: 400,
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 150,
                modifier: 1,
                slideShadows: true
            },
            pagination: {
                el: '.swiper-pagination',
                type: "bullets",
                clickable: true,
            },
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
        });
    }
}
